<template>
  <div>
    <winner-banner/>
    <title-comp title-cn="专利教具" title-en="Patent Teaching Aids" style="margin: 100px 0"/>
    <div class="context">
      财商少年秉承多种教学及产品辅助教学理念，致力于打造多元化教学体系，在融合情景教学的同时，也注重模拟人生的体验。
      因此财商少年团队精心研发出一系列独创专利教具，在财商教育的教学课程中，给予孩子最好的财商体验。
    </div>

    <img src="../../assets/img/aids/photo-1.webp" alt=""
         style="width: 1320px; height: 1080px; margin: 0 auto; display: block; border-radius: 15px;">
<!--    <div class="color-box__container">-->
<!--      <div class="color-box__left">-->
<!--        <div class="color-box__left-top"></div>-->
<!--        <div class="color-box__left-bottom"></div>-->
<!--      </div>-->
<!--      <div class="color-box__center"></div>-->
<!--      <div class="color-box__right">-->
<!--        <div class="color-box__right-top"></div>-->
<!--        <div class="color-box__right-bottom">-->
<!--          <div class="color-box__right-bottom-left"></div>-->
<!--          <div class="color-box__right-bottom-right"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="color-title" style="margin: 165px 0 75px;">
      <p class="color-title__main">财智大赢家</p>
      <ul class="color-title__bar">
        <li></li><li></li><li></li><li></li>
      </ul>
      <p class="color-title__sub">体验模拟人生系统财商游戏</p>
    </div>

    <div class="context" style="margin: 0 auto">
      这是旨在培养孩子财商意识的桌面游戏，市场罕见的游戏体验模拟人生系统。整合顶尖专家名师团队实战经验，帮助模拟人生不同阶段，种下财商种子，
      直击现时社会时事财商痛点。
    </div>
    <div class="context" style="margin: 0 auto 74px">
      通过生活场景的模拟，以及贴近现实生活的设定，让孩子们身临其境参与买卖交易，在游戏中体会财商知识的魅力。
    </div>

    <div class="game">
      <img src="../../assets/img/aids/game.png" alt="财智大赢家">
      <div class="game__text">
        <div class="game__title">
          <div class="game__title-text">财智大赢家，</div>
          <div class="game__title-text">让孩子在游戏中体验人生：</div>
        </div>
        <div class="game__content">
          <div class="game__content-text">1、在机遇到来前，做好积累和准备；</div>
          <div class="game__content-text">2、面对稳定产品与风险产品，应如何投资；</div>
          <div class="game__content-text">3、开启自己的投资理财天赋，提高财商，实现人生目标。</div>
        </div>
      </div>
    </div>

    <div class="color-title" style="margin: 170px 0 80px;">
      <p class="color-title__main">《我是小当家》</p>
      <ul class="color-title__bar">
        <li></li><li></li><li></li><li></li>
      </ul>
      <p class="color-title__sub">财商少年系列丛书</p>
    </div>

    <div class="context" style="margin: 0 auto 50px">
      生活中很多家长会因为孩子的乱花钱而烦心，相信他们都希望孩子可以学会合理地消费、有计划地管理零花钱，以及培养孩子的理财观。
    </div>

    <div class="context" style="margin: 0 auto 20px">
      通过《我是小当家》儿童财商启蒙书籍的学习，引导孩子区分“想要”和“需要”的概念，做到精明消费不浪费，教会孩子打理自己的零花钱，并养成记账的良好习惯；
      教会孩子正确对待金钱，会为“挚爱的人”消费，培养家庭责任感和社会责任感。
    </div>

    <img class="book-img" src="../../assets/img/aids/book.webp" alt="我是小当家">

    <div class="color-title" style="margin: 50px 0 75px;">
      <p class="color-title__main">《趣味财商英语》</p>
      <ul class="color-title__bar">
        <li></li><li></li><li></li><li></li>
      </ul>
      <p class="color-title__sub">财商少年英语课程系列</p>
    </div>

    <div class="context" style="margin: 0 auto 80px">
      跨学科教学，一个课堂同时收获两种学科知识，通过不同学科间的共通元素，利用传统学科作引导，让知识更易吸收，更易巩固。
      除了让孩子可以学得好的财商智慧及习惯，还能同时训练孩子更多融入生活的技能，一举两得。
    </div>

    <div class="english-book">
      <img src="../../assets/img/aids/english-1.png" alt="趣味财商英语">
      <img src="../../assets/img/aids/english-2.png" alt="趣味财商英语">
    </div>
  </div>
</template>

<script>
  import WinnerBanner from "@/components/common/WinnerBanner";
  import TitleComp from "@/views/main/childComp/TitleComp";

  export default {
    name: "TeachingAids",
    components: {
      WinnerBanner,
      TitleComp
    }
  }
</script>

<style lang="scss" scoped>
  $red: #e40073;
  $green: #95c51b;
  $yellow: #ffe100;
  $blue: #00abeb;
  // 主要文字
  .context {
    width: 1320px;
    margin: 100px auto;
    font-size: 23px;
    color: #202730;
    text-align: center;
  }

  // 彩色盒子
  .color-box__container {
    display: flex;
    width: 1100px;
    margin: 0 auto;
    height: 400px;
  }

  .color-box__left {
    width: 400px;
    .color-box__left-top {
      height: 200px;
      background-color: $green;
    }
    .color-box__left-bottom {
      height: 200px;
      background-color: $yellow;
    }
  }
  .color-box__center {
    width: 300px;
    background-color: $blue;
  }
  .color-box__right {
    width: 400px;
    .color-box__right-top {
      height: 200px;
      background-color: $red;
    }
    .color-box__right-bottom {
      height: 200px;
      display: flex;
      .color-box__right-bottom-left {
        width: 200px;
        background-color: #0068b7;
      }
      .color-box__right-bottom-right {
        width: 200px;
        background-color: $yellow;
      }
    }
  }

  // 彩色条带的标题
  .color-title {
    text-align: center;
  }
  .color-title__main {
    font-size: 50px;
  }
  .color-title__bar {
    height: 10px;
    width: 600px;
    margin: 0 auto;
    display: flex;
    li {
      width: 150px;
      background: #e00068;
      &:nth-child(2) {
        background: #ffdd06;
      }
      &:nth-child(3) {
        background: #86b719;
      }
      &:nth-child(4) {
        background: #00a2e8;
      }
    }
  }
  .color-title__sub {
    font-size: 40px;
    letter-spacing: 10px;
  }

  // 介绍财智大赢家
  .game {
    width: 1320px;
    margin: 0 auto;
    display: flex;
    img {
      display: block;
      height: 362px;
      width: 660px;
    }
  }
  .game__text {
    padding-left: 55px;
  }
  .game__title {
    height: 50px;
    border-left: 4px solid #0068b7;
    margin-bottom: 30px;
    .game__title-text {
      font-size: 24px;
      line-height: 1em;
      color: #0068b7;
      margin-left: 12px;
      &:first-child {
        margin-bottom: 2px;
      }
    }
  }
  .game__content {
    width: 600px;
  }
  .game__content-text {
    height: 60px;
    border-radius: 8px;
    margin-bottom: 24px;
    font-size: 21px;
    color: #ffffff;
    line-height: 60px;
    text-indent: 1em;
    &:first-child {
      background-color: $yellow;
    }
    &:nth-child(2) {
      background-color: $green;
    }
    &:last-child {
      background-color: $blue;
    }
  }

  // 我是小当家
  .book-img {
    width: 1200px;
    height: 681px;
    margin: 0 auto;
    display: block;
  }

  // 趣味财商英语
  .english-book {
    width: 1000px;
    margin: 0 auto 220px;
    display: flex;
    justify-content: space-between;
    img {
      display: block;
      height: 483px;
      width: 440px;
      box-shadow: 5px 5px 20px #00000055;
    }
  }

</style>